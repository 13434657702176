<template>
  <div class="authorize">
    <ul class="leftree">
      <li>
        <el-input
          v-model="filterText"
          clearable
          style="width: 100%"
          placeholder="输入系统编号检索"
        ></el-input>
        <!-- @keyup.native="onSearchNo" -->
      </li>
      <li class="system-info">账号列表</li>
      <li id="ulStyle">
        <ul class="tree">
          <li
            v-for="(item, index) in userData"
            :key="index"
            :class="{ 'active': index  == active }"
            @click="onTab(index, item.id)"
          >
            {{ item.username }}
          </li>
        </ul>
      </li>
      <el-pagination
          layout="prev, pager, next,jumper,"
          :total="userPagination.total"
          :page-size="pagination.pageSize"
          @current-change="handleUserCurrentPage"
          :pager-count="5"
        />
    </ul>
    <div class="content">
      <el-form ref="searchForm" :inline="true" :model="searchForm">
        <el-form-item label="学校名称">
          <el-input v-model="searchForm.name" placeholder="请输入学校名称"/>
        </el-form-item>
        <el-form-item class="main-form-item-float-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getDistributedPointList"
            >查询</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
          <el-button type="primary" @click="handlerDistribute()"
            >分配网点</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border stripe>
        <el-table-column
          label="网点（学校）名称"
          prop="collegeName"
          min-width="120px"
        />
        <el-table-column label="授权日期" prop="relaDate" min-width="120px" />
        <el-table-column fixed="right" label="操作" width="80">
          <template #default="scope">
            <el-button
              @click="handleDel(scope.row.collegeId)"
              type="text"
              size="small"
              >删除</el-button
            >
            <!-- <el-button @click="handleResume(scope.row.id)" type="text" size="small" v-else>恢复</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; text-align: right">
        <el-pagination
          layout="total,sizes,prev, pager, next,jumper,"
          :total="pagination.total"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="pagination.pageSize"
          @current-change="handleCurrentPage"
          @size-change="handleSizeChange"
        />
      </div>
    </div>

    <el-dialog
      custom-class="main-el-dialog"
      title="分配"
      v-model="dialogVisible"
      width="500px"
    >
      <el-table :data="unallocateData" border stripe @selection-change="handleSelectionChange" height="360">
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="待分配网点"
          prop="collegeName"
          min-width="120px"
        />
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="agreeCorrelation"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { handleCatchStatus } from "../../api/handler";
export default {
  data() {
    return {
      userData: [],
      tableData: [],
      userId:'',
      filterText: "",
      active: 0,
      userPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      dialogVisible: false,
      searchForm: {
        name: "",
      },
      unallocateData:[],
      selectKey:[]
    };
  },
  watch: {
    filterText(val) {
      this.active=0
      this.getUserInfo()
    },
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      let params = {
        username: this.filterText,
        page: this.userPagination.current- 1,
      };
      this.$api.getAdminUserPage(params).then((res) => {
        this.userData = res.content;
        this.userPagination.total = res.totalElements;
        if(this.userData.length>0){
            this.userId=this.userData[0].id
            this.getDistributedPointList()

        }
      });
    },
      //用户列表 分页
    handleUserCurrentPage(current) {
      this.active=0
      this.userPagination.current = current;
      this.getUserInfo();
    },
    onTab (num, id) {
      this.active = num;
      this.userId=id
      this.getDistributedPointList()
    },
    // 获取已分配列表
    getDistributedPointList() {
        let params = {
            collegeName: this.searchForm.name,
            page: this.pagination.current,
            pageSize:this.pagination.pageSize,
        }
      this.$api.getDistributedPoint(this.userId,params).then((data) => {
        this.tableData = data.list;
        this.pagination.total=data.total
      });
    },
    handleSelectionChange(data){
       this.selectKey=[]
       data.map(item=>{
           this.selectKey.push(item.collegeId)
       })
       
    },
    // 分页
    handleCurrentPage(current) {
      this.pagination.current = current;
      this.getDistributedPointList();
    },
    handleSizeChange(size) {
      this.pagination.pageSize = size;
      console.log(this.pagination,1111)
      this.getDistributedPointList();
    },
    // 重置
    resetForm(formName) {
      this.searchForm = { name: "" };
      this.getDistributedPointList();
    },
    //分配
    handlerDistribute() {
      let params = {
           userId:this.userId,
           pageSize:1000,
           page:1
      };
      this.$api.getUnallocateList(this.userId,params).then((res) => {
        this.unallocateData = res.list;
        this.dialogVisible=true
      });
    },
    // 确认关联
    agreeCorrelation() {
      if(this.selectKey.length===0){
         return  this.$message.warning('请先选择网点')
      }
      this.$api.allocatePoint(this.userId,this.selectKey,).then((res) => {
        this.dialogVisible=false
        this.getDistributedPointList()
      });
    },
    // 删除
    handleDel(id) {
      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => this.$api.delAllocatePoint(this.userId,{collegeId:id}))
        .then(() => {
          this.$message.success("删除成功!");
          this.getDistributedPointList()
        })
        .catch(handleCatchStatus);
    },
  },
};
</script>

<style  lang="scss" scoped>
.authorize {
  background: white;
  display: flex;
  ul {
    list-style-type: none;
  }
  .leftree {
    width: 240px;
    flex-shrink: 0;
    height: 100%;
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-right: 20px;
    margin-top:0 ;
    .search {
      margin-bottom: 10px;
    }
  }
  .system-info {
    margin-top: 10px;
    border: 1px solid #efefef;
    background: #fff;
    height: 48px;
    line-height: 48px;
    text-align: left;
    text-indent: 10px;
  }
  .tree {
    text-indent: 10px;
  }
  .content {
    flex: 1;
    flex-basis: auto;
  }
  .active {
    background: #0288d1;
    color: white !important;
    border-color: #1890ff !important;
  }
  #ulStyle {
    height: 680px !important;
    overflow: auto;
    ul {
      padding-left: 0;

      li {
        height: 48px;
        line-height: 48px;
        text-align: left;
        border-bottom: 1px solid #efefef;
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
        border-top: none;
        cursor: pointer;
      }
    }
  }
  //   padding: 20px 10px 20px 10px;
}
</style>